import { CardAppType } from 'components/cardApp';
import { DynamicImage } from 'components/Image';
import { isNotEmpty } from 'utility/functions';
import styles from './CardAppIcon.module.scss';

export type CardAppIconProps = Partial<CardAppType>;

export const CardAppIcon = ({ title, image, className }: CardAppIconProps) => {
  return (
    <div className={`${className ? className : styles.headingContainer}`}>
      {isNotEmpty(image?.src) && (
        <DynamicImage
          src={`${image?.src}`}
          alt={image?.alt ?? ''}
          title={image?.title ?? ''}
          className={styles.image}
          height={64}
          width={64}
          loading="lazy"
          decoding="async"
        />
      )}
      {title && (
        <div className={styles.titleContainer}>
          <span className={styles.title}>{title}</span>
        </div>
      )}
    </div>
  );
};
