import React from 'react';
import classNames from 'classnames';
import { decode } from 'he';
import { parse } from 'node-html-parser';
import styles from './RichText.module.scss';

export type RichTextProps = {
  children?: string | undefined;
  containerTag?: string;
  containerClassName?: string;
};

export const RichText = ({ children, containerTag, containerClassName }: RichTextProps) => {
  let content = children ?? '';
  const decodedHtml = decode(content);
  const objChild = parse(decodedHtml);

  return React.createElement(containerTag ?? 'div', {
    className: classNames(styles.container, containerClassName ?? ''),
    dangerouslySetInnerHTML: { __html: objChild.outerHTML },
  });
};
