import { AnchorLink } from 'components/anchorLink';
import { Card } from '../components/card';
import { CardFallbackImage } from '../components/cardFallbackmage/CardFallbackImage';
import { DynamicImage } from 'components/Image';
import { ImageProps } from 'next/image';
import React from 'react';
import { RichText } from 'components/richText';
import { isNotEmpty } from 'utility/functions';
import styles from './PokerCard.module.scss';

export type PokerCardProps = {
  id: string;
  title: string;
  image: ImageProps | null; // può essere null in quando il match con il middleware può non avvenire
  slug: string;
  description: string;
  containerClass?: string;
};

export const PokerCard = ({ ...props }: PokerCardProps) => {
  return (
    <Card containerClass={props.containerClass}>
      <AnchorLink href={props.slug} className={styles.linkWrapper}>
        <h4 className={styles.title}>{props.title}</h4>
        {isNotEmpty(props?.image?.src) ? (
          <React.Fragment>
            <div className={styles.mask}></div>
            <DynamicImage
              src={`${props?.image?.src}`}
              alt={props?.image?.alt ?? ''}
              fill
              loading="lazy"
              decoding="async"
              className={styles.image}
            />
          </React.Fragment>
        ) : (
          <CardFallbackImage />
        )}
      </AnchorLink>
      <RichText containerClassName={styles.textContent}>{props.description}</RichText>
    </Card>
  );
};
