import { Card, CardProps } from '../components/card/Card';
import { CardImageContainer, CardImageContainerProps } from '../components/card/cardImageContainer/CardImageContainer';
import { useCallback, useMemo, useState } from 'react';
import { useRetrieveTableInfo, useSubscribeToTable } from 'features/casino/hooks';

import { CardDealerChip } from '../components/cardDealerChip';
import { CardExclusiveLabel } from '../components/cardExclusiveLabel';
import { CardImageFooter } from '../components/card/cardImageFooter';
import { CardImageHeader } from '../components/card/cardImageHeader';
import { CardRouletteList } from '../components/cardRouletteList';
import { CardTextContainer } from '../components/card/cardTextContainer';
import { CardWinnability } from '../components/cardWinnability';
import { CasinoLiveCardDescription } from './casinoLiveCardDescription';
import { CasinoLiveClosed } from './casinoLiveClosed';
import { DynamicImage } from 'components/Image';
import { NumberProps } from '../components/cardRouletteList/CardRouletteList';
import { Table } from 'types/casino-live';
import { TableStatus } from 'types/casino-live/catalog-response';
import { breakpoint } from 'utility/constant';
import { callbackOnAuthenticated } from 'utility/auth';
import { isNotEmpty } from 'utility/functions';
import { launchGameCasinoLive } from 'lib/launchGame/utils';
import styles from './CasinoLiveCard.module.scss';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useInternalSession } from 'hooks/useInternalSession';
import { useTypedSelector } from 'lib/centralStore';
import useWindowSize, { enComparer } from 'hooks/useWindowSize';

export type CasinoLiveCardProps = Partial<CardProps & CardImageContainerProps & Table>;

export const CasinoLiveCard = ({
  containerClass,
  isCover,
  dealer,
  id,
  name,
  dedicated,
  language,
  freeSeatsCount,
  max,
  min,
  lastNumbers,
  status,
  tableColor,
  imsGameType,
  providerId,
  ...props
}: CasinoLiveCardProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const startGameLabel = useGetLabelByKey('gioca');
  const labelExclusive = useGetLabelByKey('in-esclusiva');
  const { session } = useInternalSession();

  const getFormattedNumber = (number: number): NumberProps => {
    const redValues = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
    return {
      value: number,
      bgColor: redValues.find((value) => value === number) ? 'red' : 'black',
    };
  };

  const { doCheck } = useWindowSize();

  const [isMobile] = doCheck([{ comparer: enComparer.less, size: breakpoint.lg }]);

  const { isAuthenticated } = useInternalSession();
  const { cardNumber } = session?.user ?? {};

  const tableInfo = useRetrieveTableInfo(providerId);
  useSubscribeToTable(providerId);

  const dealerImage = useMemo(() => {
    const imageUrl = tableInfo?.tableImage || props.dynamicImageUrl;
    const dealerName = (tableInfo?.dealer.name || dealer?.dealerName) ?? '';
    return isNotEmpty(imageUrl) ? (
      <DynamicImage className={styles.dealer} alt={dealerName} src={imageUrl!} fill loading="lazy" decoding="async" />
    ) : null;
  }, [dealer?.dealerName, props.dynamicImageUrl, tableInfo?.dealer.name, tableInfo?.tableImage]);

  const handleLaunchGame = useCallback(() => {
    launchGameCasinoLive({
      id: id!,
      isMobile,
      cardNumber: cardNumber!,
      imsGameType: imsGameType!,
      launchAlias: props.launchAlias!,
    });
  }, [cardNumber, id, imsGameType, props.launchAlias, isMobile]);

  return (
    <Card containerClass={containerClass}>
      {status === TableStatus.Online ? (
        <CardImageContainer
          handleLaunchGame={() => callbackOnAuthenticated(isAuthenticated, handleLaunchGame)}
          bgColor={`#${tableColor}`}
          playLabel={startGameLabel}
          isHover={isHover}
          handleHover={setIsHover}
          isCover={isCover!}
          showImage={false}
          hasGamePlatform
        >
          {dealerImage}
          <CardImageHeader isStickToTop>
            {dealer?.dealerName && <CardDealerChip name={dealer.dealerName} />}
          </CardImageHeader>
          <CardImageFooter hidden={isHover}>
            {dedicated && <CardExclusiveLabel label={labelExclusive!} />}
            {min && max && <CardWinnability max={max} min={min} />}
            {lastNumbers?.length && <CardRouletteList listItems={lastNumbers.map(getFormattedNumber)} />}
          </CardImageFooter>
        </CardImageContainer>
      ) : (
        <CasinoLiveClosed />
      )}
      <CardTextContainer title={name!}>
        <CasinoLiveCardDescription language={language!} seatsAvailable={freeSeatsCount} />
      </CardTextContainer>
    </Card>
  );
};
