import { Card } from 'components/cards/components/card';
import { CardFavouriteBtn } from 'components/cards/components/cardFavouriteBtn';
import { DynamicImage } from 'components/Image';
import { LazyGetTriggerType } from 'features/slotArchive/actions';
import { UsersUserDataSectionEnum } from 'types/swagger';
import { isNotEmpty } from 'utility/functions';
import styles from './FavTeamCard.module.scss';
import { useInternalSession } from 'hooks/useInternalSession';

export type FavTeamCardProps = {
  name: string;
  maglia: string;
  id: string;
  triggerRefetch?: LazyGetTriggerType;
};

export const FavTeamCard = ({ name, maglia, id, triggerRefetch }: FavTeamCardProps) => {
  const { isAuthenticated } = useInternalSession();

  return (
    <Card containerClass={styles.container}>
      <div className={styles.wrapper}>
        {isAuthenticated && (
          <CardFavouriteBtn
            idGame={id!}
            favourite={true}
            verticale={UsersUserDataSectionEnum.FAVORITE_SPORT_SQUADRA}
            triggerRefetch={triggerRefetch}
          />
        )}
        {isNotEmpty(maglia) && (
          <div className={styles.imageContainer}>
            <DynamicImage
              src={maglia}
              alt={name ?? ''}
              width={24}
              height={24}
              loading="lazy"
              decoding="async"
              className={styles.image}
            />
          </div>
        )}
        <span className={styles.name}>{name}</span>
      </div>
    </Card>
  );
};
